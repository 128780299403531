/* Universals -------------------------------------- */
html {
  box-sizing: content-box;
  margin: 10px 30px 0 30px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  background-color: #0F3F4F;
  color: white;
  overflow-y: overlay;
}

@font-face {
  font-family: 'Gagalin';
  src: url('./fonts/Gagalin-Regular.ttf') format('truetype'),
       url('./fonts/Gagalin-Regular.woff2') format('woff2'),
       url('./fonts/Gagalin-Regular.woff') format('woff');
  font-family: 'Hubballi';
  src: url('./fonts/Hubballi-Regular.ttf') format('truetype');
}

h1, h2 {
  font-family: 'Gagalin';
  font-size: 45px;
  letter-spacing: .1rem; 
}

h2 {
  font-size: 20px;
  margin: 0 0 -5px 0;
}

p, li {
  font-family: 'Hubballi';
  font-size: 22px;
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

a {
  color: #0F3F4F;
}

/* NavBar ------------------------------------------ */

.nav-menu {
  margin-left: -32px;
  margin-right: -32px;
  padding: 15px 32px;
  text-align: center;
  border-bottom: 1px solid white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-menu a {
  color: white;
  text-decoration: none;
  font-size: 26px;
  display: inline-block;
  margin: 10px;
  width: 120px;
  font-family: 'Hubballi';
}

div.nav-home {
  text-align: left;
  margin: 0;
}

div.nav-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

div.nav-links li {
  display: inline;
}

div.nav-links li a {
  padding-top: 10px;
  width: 40px;
  height: auto;
}

div.nav-links li#medium-icon a {
  margin-right: 0px;
  margin-left: 0px;
}

div.nav-links li img {
  width: 45px;
}

a#home-link {
  margin: 0;
  padding: 0;
  width: auto;
}

/* Footer ----------------------------------------------- */

.footer {
  position: relative;
  bottom: 0;
  left: 0;
  background-color: white;
  color: #0F3F4F;
  margin-top: 40px;
  margin-left: -32px;
  margin-right: -32px;
  padding: 15px 32px;
  padding: 6px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-menu {
  display: flex;
  align-items: center;
}

.footer-menu a {
  color: #0F3F4F;
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
  margin: 10px;
  width: 60px;
  font-family: 'Hubballi';
}

div.footer-home {
  text-align: left;
  margin: 0;
}

.footer-copyright p {
  font-size: 18px;
}

a#footer-link {
  margin-right: 20px;
  width: auto;
}

a#footer-link img {
  height: 40px;
}

/* Page Setup ------------------------------------------- */

.page-title {
  color: #0F3F4F;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 2px;
  width: 280px;
  text-align: center;
  border-radius: 3px;
  margin-left: auto;
  align-items: right;
  margin-right: -114px;
}

.body-container {
  padding-left: 80px;
  padding-right: 80px;
  min-height: calc(100vh - 110px);
}

/* Home ----------------------------------------------- */

#home-intro {
  font-size: 40px;
}

.intro-p {
  font-size: 28px;
}

#profile-img {
  width: 310px;
  border: 2px solid white;
  border-radius: 3px;
}

.home-parent-block {
  vertical-align: middle;
  padding-top: 60px;
  padding-bottom: 60px;
}

.home-child-block {
  display: inline-block;
  vertical-align: top;
}

.home-child-block#one {
  width: 60%;
  margin-right: 55px;
}

/* About ----------------------------------------------- */

#about-img {
  width: 220px;
  border: 2px solid white;
  border-radius: 3px;
}

.about-parent-block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
}

.child-one {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.child-two {
  grid-column: 2 / 5;
  grid-row: 1 / 2;
  margin-left: -40px;
  margin-top: -24px;
}

.child-two p {
  font-size: 19px;
}

.child-three {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
}

.child-four {
  grid-row: 2 / 3;
  grid-column: 3 / 5;
}

/* Portfolio --------------------------------- */

.all-tiles-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 25px;
  margin-bottom: 30px;
  align-content: center;
}

.project-tile {
  text-decoration: none;
}

.portfolio-title {
  background-color: white;
  color: #0F3F4F;
  /* color: white; */
  text-align: center;
  /* border: 2px solid white; */
  border-radius: 3px;
  margin-bottom: 6px;
  vertical-align: middle;
}

.portfolio-title h1 {
  font-size: 16px;
  width: inherit;
  padding: 10px 0;
  margin: 0;
}

.portfolio-image {
  /* border: 2px solid white;*/
  border-radius: 3px;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  height: 250px;
}

.portfolio-image img {
  width: 100%;
  object-fit: contain;
  padding: 10px;
}

/* One Portfolio Project -------------------------- */

.one-project-container {
  margin: 35px 0 0 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 25px;
}

div.bts-column {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
}

.summary-box {
  background-color: white;
  border-radius: 3px;
  color: #0F3F4F;
  padding: 30px;
  position: relative;
}

div.summary-box p {
  font-size: 16px;
}

#summary-h2 {
  font-size: 16px;
}

.bts-img {
  border: 2px solid white;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  height: 95.5%;
  background-color: white;
}

.bts-img img {
  width: 100%;
}

.bts-no-img {
  border: 2px solid white;
  border-radius: 3px;
  box-sizing: border-box;
  height: 95%;
  background-color: rgb(233,233,233,.25);
  color: white;
  font-family: 'Hubballi';
  text-align: center;
}

.snapshot {
  border: 2px solid white;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  height: 360px;
}

.snapshot img {
  width: 100%;
  object-fit: fill;
}

#project-links-p {
  position: absolute;
  bottom: 30px;
  margin-bottom: 0;
}

.project-links:hover {
  text-decoration: none;
}

.project-links:visited {
  color: inherit;
}

.back-btn-container {
  text-align: center;
  margin-top: 25px;
  margin-bottom: -15px;
}

#back-btn {
  color: white;
  text-decoration: none;
}

/* Contact -------------------------------------- */

.contact-h2 {
  width: 35%;
  font-size: 50px;
}

.contact-plane {
  position: absolute;
  width: 500px;
  top: 200px;
  left: 450px;
  z-index: -1;
}

.contact-p {
  float: right;
  width: 35%;
  margin-bottom: 30px;
}

.contact-p p {
  font-size: 26px;
}

#email-btn {
  font-family: 'Hubballi';
  font-size: 26px;
  padding: 7px 20px 14px 20px;
  color: white;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 3px;
  margin-top: 10px;
}

#email-btn:hover {
  background-color: rgb(255, 255, 255,.2);
  cursor: pointer;
}

#email-notification {
  font-size: 20px;
}